/* Workvision Styling */
@import 'start';

*,
*:before,
*:after {
  @include box-sizing(border-box !important);
}

html {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

#app {
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

/**
  * Spacer
**/
.spacer {
  display: block;
  height: 10px;
  width: 10px;
}

.pageloader {
  background: $color-workvision-lightgray !important;
}

/**
  * Input Custom
**/
.field {
  .control {
    @include flexbox();

    > input,
    > .select {
      min-width: 1px;
    }

    > figure {
      @include flex(0 0 32px);

      + input,
      + .select {
        margin-left: 10px;
      }
    }
  }
}

/**
  * Navbar Custom
 **/

/* Mobile-Desktop-Nav Breakpoint Changes */
@import 'partials/navbar-custom';

// Allgemein

body {
  #navigation {
    background-color: $color-workvision-gray;
    color: $fontcolor-header;
    height: $nav-mobile-height;

    .navbar {
      background-color: transparent;
      @include flex-wrap(wrap);
      position: relative;

      .navbar-brand {
        font-size: 12px;
        padding: 14px 20px;
        width: 100%;
        border-bottom: 2px solid $color-workvision-yellow;
        height: $brand-height;
        line-height: #{$brand-height - 14 - 14};
        @media screen and (min-width: $bp-smartlet) {
          font-size: 16px;
        }
        @media screen and (min-width: $bp-tablet) {
          font-size: 24px;
        }
      }

      .navbar-burger {
        background-color: transparent;
        margin-top: -9px;
        margin-bottom: -9px;

        > span {
          border-bottom: 1px solid $fontcolor-header;
        }
      }

      .navbar-menu {
        background-color: $color-workvision-gray;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;

        height: calc(100vh - #{$nav-mobile-height}); /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100 - #{$nav-mobile-height});
        overflow-y: auto;
      }

      .navbar-item {
        background-color: transparent;
        padding: 3px 0;

        .navbar-dropdown {
          background-color: #fff;
          position: static;
        }

        button {
          width: 100%;
          @include flexbox();
          @include justify-content(center);
          background-color: $color-workvision-lightgray;
          color: $fontcolor-header;
          height: auto;
          font-size: 10px;
          padding: 9px;
          text-align: center;

          > figure {
            margin: 0;
            height: 24px;
            width: 24px;

            > img {
              max-height: 999px;
            }
          }

          > span {
            display: block;
            width: 100%;
            text-align: left;
            padding-left: 10px;
            font-size: 12px;
          }
        }

        &.has-dropdown {
          button {
            @include border-radius(4px);
            background: transparent;
            width: 100%;
            cursor: default;
            &:focus,
            &:active {
              @include box-shadow(0 0 0 0.125em rgba(245, 245, 245, 0));
            }
            &:focus,
            &:focus-within,
            &:hover,
            &:active,
            &:visited {
              border-color: #dbdbdb;
              outline: 0;
            }
          }

          .navbar-dropdown {
            border-top: 0px solid transparent;
            @include box-shadow(0 8px 8px rgba(10, 10, 10, 0));

            .navbar-divider {
              display: block;
              height: 1px;
              border-top: 1px solid $color-workvision-lightgray;
              margin: 0;
            }
            a {
              padding: 9px;
              color: $fontcolor-header;
            }
          }
        }
      }

      .navbar-link {
        background-color: transparent;
        padding: 0;
      }

      .navbar-divider-custom {
        display: block;
        > div {
          display: block;
          background-color: $color-workvision-lightgray;
          height: 1px;
          width: 100%;
        }
      }

      .navbar-start,
      .navbar-end {
        max-width: 300px;
        margin: {
          left: auto;
          right: auto;
        }
      }
    }
  }
}
@include until($bp-desktop) {
  body {
    #navigation {
      display: block;
      .navbar {
        display: block;

        .navbar-menu {
          display: none;
          &.is-active {
            display: block;
          }
        }

        .navbar-item {
          button {
            padding: 7px 9px;
          }
          &.navbar-divider-custom {
            display: none;
          }
          &.has-dropdown {
            .navbar-dropdown {
              display: none;
              background: transparent;
            }
            &.is-active {
              .navbar-dropdown {
                display: block;
              }
            }
          }
        }
      }
    }
    .navbar,
    .navbar-menu,
    .navbar-start,
    .navbar-end {
      display: block;
    }
  }
}
@include from($bp-desktop) {
  body {
    #navigation {
      height: $nav-desktop-height;

      .navbar {
        .navbar-menu {
          height: $menu-height;
          overflow-y: visible;
        }

        .navbar-item {
          padding: 10px 2px;

          @media screen and (min-width: 1350px) {
            padding: 10px 5px;
          }

          button {
            padding: 5px;
            display: block;

            @media screen and (min-width: 1350px) {
              font-size: 12px;
            }
            @media screen and (min-width: 1650px) {
              font-size: 14px;
            }

            > figure {
              margin-left: auto;
              margin-right: auto;
            }

            > span {
              height: 18px;
              text-align: center;
              padding: 2px 0 0 0;
              line-height: 1em;
              height: 26px;
              @media screen and (min-width: 1350px) {
                font-size: 12px;
              }
              @media screen and (min-width: 1650px) {
                font-size: 14px;
              }
            }
          }

          &.has-dropdown {
            button {
              @include border-radius(4px);
              background-color: $color-workvision-lightgray;
              width: auto;
              cursor: pointer;
            }

            .navbar-dropdown {
              background: #ffffff;
              position: absolute;
              border: 1px solid $color-divider;
              .navbar-divider {
                border-top: 1px solid $color-divider;
                margin: 0.5em 0;
              }
              a {
                color: $color-workvision-gray;
              }
            }
          }
        }

        .navbar-divider-custom {
          > div {
            width: 1px;
            height: 100%;
          }
        }

        .navbar-start,
        .navbar-end {
          margin: 0;
          max-width: 9999px;
        }
        .navbar-start {
          .navbar-item {
            button {
              min-width: 70px;
              @media screen and (min-width: 1350px) {
                min-width: 85px;
              }
              @media screen and (min-width: 1650px) {
                min-width: 109px;
              }
            }
          }
        }

        .navbar-end {
          position: absolute;
          top: 4px;
          right: 6px;
          margin-top: 0px;
          padding-top: 0px;
          border-top: 0px dashed #505e69;
        }
      }
    }
  }
}

/**
  * #content
 **/
#app {
  #content {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - #{$nav-mobile-height});
    height: calc(var(--vh, 1vh) * 100 - #{$nav-mobile-height});

    @media screen and (min-width: $bp-desktop) {
      height: calc(100vh - #{$nav-desktop-height});
      height: calc(var(--vh, 1vh) * 100 - #{$nav-desktop-height});
    }

    > .columns {
      min-height: 100%;
    }
  }

  .columns {
    margin: 0;
    > .column {
      padding: 20px;

      .columns {
        margin-left: -2px;
        margin-right: -2px;

        @media screen and (min-width: $bp-desktop) {
          margin-left: -10px;
          margin-right: -10px;
        }

        > .column {
          padding: 10px 2px;

          @media screen and (min-width: $bp-desktop) {
            padding: 10px;
          }
        }
      }
    }
  }

  .table tr.is-selected {
    background-color: $color-workvision-blue;
  }

  .tr-hover:hover {
    background-color: $color-workvision-blue;
    color: white;
    cursor: pointer;
  }

  .bullet {
    display: inline-block;
    height: 15px;
    width: 15px;
    background: $color-workvision-lightestgray;
    border: 1px solid #757575;
    @include border-radius(50%);

    &.green {
      background-color: #86c129;
      border: 1px solid darken(#86c129, 10%);
    }

    &.red {
      background-color: #e74c3c;
      border: 1px solid darken(#e74c3c, 10%);
    }

    &.yellow {
      background-color: #f1c40f;
      border: 1px solid darken(#f1c40f, 10%);
    }
  }

  .table-wrap {
    overflow-x: auto;
    max-width: 1000px;
    margin: {
      left: auto;
      right: auto;
    }

    .align-left {
      text-align: left;
    }

    .align-center {
      text-align: center;
    }

    .align-right {
      text-align: right;
    }
  }
}

/**
  * breadcrumb
 **/

.breadcrumb-custom {
  display: block;
  padding: 6px 20px;

  .breadcrumb-item {
    display: inline;

    &:not(:first-child) {
      &:before {
        content: '/';
        display: inline-block;
        margin-left: 6px;
        margin-right: 6px;
        opacity: 0.35;
      }
    }
  }
}

.column > .breadcrumb-custom {
  margin: {
    left: -20px;
    right: -20px;
  }

  &:first-child {
    margin: {
      top: -20px;
    }
  }

  &:last-child {
    margin: {
      bottom: -20px;
    }
  }
}

/**
  * HR
 **/

#root hr {
  margin: 0;
  padding: 0;
  height: 1px;
  background-color: $color-divider;
}

/**
  * classList
 **/

/* Border */
.border {
  border: 1px solid $color-divider;
}
.border-top {
  border-top: 1px solid $color-divider;
}
.border-right {
  border-right: 1px solid $color-divider;
}
.border-bottom {
  border-bottom: 1px solid $color-divider;
}
.border-left {
  border-left: 1px solid $color-divider;
}

/**
Checkboxen und Radios mit Bilder
**/
.radio,
.checkbox {
  > figure {
    display: inline-block;
    vertical-align: top;
  }
}

/**
Radio-Buttons verticalAlign
**/
.vertAlign {
  flex-direction: column;
  .radio {
    margin-left: 0em !important;
  }
}

.radio > input[type='radio'],
.checkbox > input[type='checkbox'] {
  margin-right: 5px;
  margin-bottom: 5px;
}

/**
  * InstructionElement
 **/
.instruction-wrap {
  @media screen and (max-width: 768px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  > .instruction-txtcol {
    border-bottom: 1px solid $color-divider;
    padding: 20px;
  }
  > .instruction-imgcol {
    padding: 20px 0;
    text-align: center;
    > .instruction-imgwrap {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      max-width: 600px;
      width: 100%;
      height: auto;
      vertical-align: top;

      > .image {
        max-width: 600px;
        width: 100%;
        height: auto;
        > img {
          height: auto;
        }
      }

      > .screwpoint-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;

        > .screwpoint-item {
          position: absolute;
          top: 0;
          left: 0;
          height: 0;
          width: 0;

          > div {
            width: 24px;
            height: 24px;
            display: block;
            @include border-radius(50%);
            @include transform(translate(-50%, -50%));
            background: $color-workvision-lightestgray;
            border: 1px solid $color-white;
            position: relative;

            > div {
              height: 100%;
              width: 100%;
              @include border-radius(50%);
              text-align: center;
              line-height: 20px;
              border: 1px solid #757575;
            }
          }

          &.blink > div > div {
            animation: blink-animation 1s steps(2, start) infinite;
            -webkit-animation: blink-animation 1s steps(2, start) infinite;
          }
        }
      }
    }
  }

  &.text-left {
    display: block;
    @media screen and (min-width: 1000px) {
      @include flexbox();
    }

    > .instruction-txtcol {
      width: 100%;
      @media screen and (min-width: 1000px) {
        width: 40%;
      }
      border-right: 1px solid $color-divider;
      border-bottom: 0;
    }
    > .instruction-imgcol {
      width: 100%;
      @media screen and (min-width: 1000px) {
        width: auto;
        @include flex(1 auto);
      }
    }
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

// Screwpoint Sizes

#app {
  &.screwpointsize-16 {
    .screwpoint-item > div {
      height: 16px !important;
      width: 16px !important;
      font-size: 12px !important;

      > div {
        line-height: 12px !important;
      }
    }
  }
  &.screwpointsize-24 {
    .screwpoint-item > div {
      height: 24px !important;
      width: 24px !important;
      font-size: 14px !important;

      > div {
        line-height: 20px !important;
      }
    }
  }
  &.screwpointsize-32 {
    .screwpoint-item > div {
      height: 32px !important;
      width: 32px !important;
      font-size: 16px !important;

      > div {
        line-height: 28px !important;
      }
    }
  }
  &.screwpointsize-48 {
    .instruction-wrap > .instruction-imgcol > .instruction-imgwrap > .screwpoint-wrap > .screwpoint-item > div {
      height: 48px;
      width: 48px;
      font-size: 24px;

      > div {
        line-height: 42px;
      }
    }
  }
  &.screwpointsize-64 {
    .instruction-wrap > .instruction-imgcol > .instruction-imgwrap > .screwpoint-wrap > .screwpoint-item > div {
      height: 64px;
      width: 64px;
      font-size: 32px;

      > div {
        line-height: 58px;
      }
    }
  }
}

// Font Sizes

html {
  &.fontsize-10 {
    font-size: 10px;
  }
  &.fontsize-12 {
    font-size: 12px;
  }
  &.fontsize-16 {
    font-size: 16px;
  }
  &.fontsize-20 {
    font-size: 20px;
  }
}

// Button Group
.button-group {
  @include flexbox();
  margin: {
    left: -2px;
    right: -2px;
  }

  > * {
    @include flex(1 1 0 auto);
    margin: 0 2px;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 1200ms infinite;
  -webkit-animation: fade 1200ms infinite;
}

.modal-card > form {
  @include flexbox();
  @include flex-direction(column);
  max-height: 90vh;
}
