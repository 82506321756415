// Schriftfamilie
$font-family-sans: 'Roboto', Arial, Helvetica, sans-serif;
$font-family-sans: Arial, Helvetica, sans-serif;

// Allgemeine Farben
$color-black: #000;
$color-chineseblack: #111;
$color-white: #fff;
$color-blue: #09f;
$color-darkblue: #00519e;
$color-menuactive: #efefef;
$color-lightgrey: #d1dadf;
$color-grey: #bbb;
$color-silver: #aab0b4;
$color-darkgrey: #777;
$color-anthracite: #333;
$color-kotzgreen: #696;
$color-red: #c00;
$color-border: #b2b2b2;
$color-box: #b9bfc2;
$color-alert-error: #ff5252;
$color-alert-success: #15af09;

// Kunden-Farben
$color-workvision-gray: #353f48;
$color-workvision-lightgray: #505e69;
$color-workvision-lightestgray: #f5f5f5;
$color-workvision-blue: #3273dc;
$color-workvision-yellow: #f3cf09;

$color-divider: #dbdbdb;

$fontcolor-header: #ffffff;

// Schrift
$font-weight-text: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-header: 700;
$font-weight-black: 900;

// Pfade
$imgPath: '../img/'; // Von "assets/css" ausgehend
$fontPath: '../fonts/'; // Von "assets/css" ausgehend

// Breakpoints
$bp-smartlet: 520px;
$bp-tablet: 768px;
$bp-desktop: 1200px;
$bp-menu: 1150px;
$bp-wide: 1340px;

// Layout Variables
$brand-height: 65px;
$menu-height: 82px;
$nav-mobile-height: $brand-height;
$nav-desktop-height: calc(#{$menu-height} + #{$brand-height});
$content-mobile-fullheight: calc(100vh - #{$nav-mobile-height});
$content-desktop-fullheight: calc(100vh - #{$nav-desktop-height});
